<template>
  <div class="star">
    <input
      class="star__input v-h"
      type="radio"
      :name="name"
      :id="id"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :required="required"
      :checked="checked"
    />
    <label :for="id" class="star__label">
      <transition @leave="tooltipLeave" @enter="tooltipEnter" :css="false">
        <div class="star__tooltip" ref="tooltipRef" v-show="showTooltip">
          {{ tooltip }}
        </div>
      </transition>
      <svg class="star__icon" v-svg size="0 0 36 36" symbol="star"></svg>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.star {
  position: relative;
  color: inherit;
}

.star__tooltip {
  position: absolute;
  bottom: 150%;
  left: 50%;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  padding: 8px 12px;
  color: $c-black;
  font-size: 1.5rem;
  line-height: 2rem;
  background-color: $c-surface-light-accent;
  transform: translate(-50%, -5px);
  transition: all 250ms $easing;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 14px 7px 0 7px;
    border-color: $c-surface-light-accent transparent transparent transparent;
  }
}

.star__label {
  color: inherit;
  cursor: pointer;
  display: block;
}

.star__icon {
  width: 3.2rem;
  height: auto;
  fill: currentColor;
}
</style>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import gsap from 'gsap'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      required: true,
      default: false,
    },
    value: {
      required: true,
    },
    checked: {
      required: false,
    },
    required: {
      type: Boolean,
    },
  },
  setup(props, ctx) {
    const tooltipRef = ref()

    const tooltipEnter = (el, done) => {
      gsap.killTweensOf(el)
      gsap.set(el, {
        zIndex: 1,
      })
      gsap.fromTo(
        el,
        {
          y: 10,
          opacity: 0,
        },
        {
          opacity: 1,
          y: 0,
          ease: 'power4.out',
          duration: 0.25,
          onComplete: done,
        }
      )
    }

    const tooltipLeave = (el, done) => {
      gsap.killTweensOf(el)
      gsap.set(el, {
        zIndex: -1,
      })
      gsap.to(el, {
        opacity: 0,
        y: -10,
        ease: 'power2.in',
        duration: 0.2,
        onComplete: done,
      })
    }

    onMounted(() => {
      if (props.checked) {
        ctx.emit('input', props.value)
      }
    })

    return {
      tooltipRef,
      tooltipEnter,
      tooltipLeave,
    }
  },
})
</script>
