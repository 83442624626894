
































































































































































































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  set,
} from '@vue/composition-api'
import { useRouter, useGetters } from '@u3u/vue-hooks'
import SingleWrapper from '@/components/SingleWrapper.vue'
import SatisfactionQuestion from '@/components/SatisfactionQuestion.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import gsap from 'gsap'

const URL_PARAMS = ['name', 'company', 'mail', 'project', 'mission', 'report']

interface formData {
  params: Record<string, string>
  questions: Question[]
  feedback: string
}

interface Question {
  id: string
  value: string | (string | null)[] | null
}

export default defineComponent({
  name: 'about',
  components: {
    SingleWrapper,
    SatisfactionQuestion,
  },
  setup() {
    const loading = ref(false)
    const feedback = ref()
    const router = useRouter()
    const { content, currentLang } = useGetters(['content', 'currentLang'])
    const step = ref(1)

    const formData: formData = reactive({
      params: {},
      questions: [],
      feedback: '',
      lang: currentLang,
    })

    // Fetch "params" value from URL queries. Use empty string as default
    URL_PARAMS.forEach(param => {
      set(formData.params, param, router.route.value.query[param] || '')
    })

    content.value.questions.items.forEach((question, i) => {
      formData.questions.push({
        id: question.id,
        value: i === 0 ? router.route.value.query.note : null,
      })
    })

    // First step question
    const firstStepQuestion = computed(() => {
      if (content?.value?.questions?.items?.length > 0) {
        return content.value.questions.items[0]
      }

      return null
    })

    // Second step questions
    const secondStepQuestions = computed(() => {
      if (content?.value?.questions?.items?.length > 0) {
        return content.value.questions.items.slice(1)
      }

      return []
    })

    const setQuestionValue = (id, value) => {
      const questionIndex = formData.questions.findIndex(q => q.id === id)

      if (questionIndex > -1) {
        formData.questions[questionIndex].value = value
      }
    }

    const onSubmit = () => {
      feedback.value = null
      loading.value = true

      // Breack reactivity
      const nonReactiveData = JSON.parse(JSON.stringify(formData))

      // Convert questions values from string to numbers as requested by BEMAC
      if (
        nonReactiveData.questions &&
        Array.isArray(nonReactiveData.questions)
      ) {
        for (let i = 0; i < nonReactiveData.questions.length; i++) {
          if (nonReactiveData.questions[i].value) {
            nonReactiveData.questions[i].value = parseInt(
              nonReactiveData.questions[i].value,
              10
            )
          }
        }
      }

      axios
        .post(`${getApiUrl()}/survey`, nonReactiveData)
        .then(response => {
          if (step.value === 1) {
            if (response && response.data.message) {
              step.value = 2
            } else {
              console.error(response)
            }
          } else if (step.value === 2) {
            if (response && response.data.message) {
              feedback.value = response.data.message
              if (response.data.code === 200) {
                window.setTimeout(() => {
                  router.router.push('/')
                }, 4500)
              }
            } else {
              console.error(response)
            }
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const feedbackMessageEnter = (el, done) => {
      gsap.fromTo(
        el,
        {
          opacity: 0,
          y: 10,
        },
        {
          opacity: 1,
          y: 0,
          ease: 'power4.out',
          duration: 1,
          onComplete: done,
        }
      )
    }

    const feedbackMessageLeave = (el, done) => {
      gsap.to(el, {
        opacity: 0,
        y: 10,

        ease: 'power4.out',
        duration: 1,
        onComplete: done,
      })
    }

    return {
      content,
      setQuestionValue,
      onSubmit,
      formData,
      loading,
      feedback,
      feedbackMessageLeave,
      feedbackMessageEnter,
      step,
      firstStepQuestion,
      secondStepQuestions,
    }
  },
})
