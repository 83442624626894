<template>
  <div class="question mb-xl">
    <div class="question__headline">{{ headline }}</div>

    <div class="question__content mb-l">
      <div class="question__label">{{ title }}</div>

      <div class="question__inputs">
        <div
          class="question__stars"
          :class="{
            'has-value': checked,
          }"
        >
          <SatisfactionQuestionStar
            v-for="(note, i) in content.notes"
            :key="i"
            class="question__star"
            :name="`satisfaction-${id}`"
            :id="`satisfaction-${id}-${i}`"
            :tooltip="note.label"
            :showTooltip="note.value === activeTooltip"
            :value="note.value"
            @input="onInput"
            @mouseenter.native="activeTooltip = note.value"
            @mouseleave.native="activeTooltip = null"
            :class="{
              checked: note.value === checked,
            }"
            :required="checked !== 0"
            :checked="note.value === value"
          />
        </div>
        <div class="question__na" v-if="nullable">
          <input
            class="v-h question__na__input"
            type="checkbox"
            :name="`satisfaction-${id}`"
            :id="`satisfaction-${id}-na`"
            @input="onNAInput"
            v-model="na"
          />
          <label :for="`satisfaction-${id}-na`" class="question__na__label">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="question__na__svg"
            >
              <rect
                width="22"
                height="22"
                x="1"
                y="1"
                class="question__na__svg__stroke"
                stroke-width="2"
                rx="11"
              />
              <rect
                class="question__na__svg__bullet"
                width="12"
                height="12"
                x="6"
                y="6"
                rx="6"
              />
            </svg>
            {{ content.naLabel }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.question {
  border-bottom: 1px solid currentColor;
}

.question__headline {
  font-size: 1.5rem;
  line-height: 2;
  opacity: 0.4;
  transition: opacity 150ms $easing;
  margin-bottom: 1rem;
}

.question__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq($from: l) {
    flex-direction: row;
  }
}

.question__label {
  width: 100%;
  font-size: 2rem;
  line-height: 1.5;
  transition: opacity 150ms $easing;
  margin-bottom: 2rem;

  @include mq($from: m) {
    width: col(8, 12);
  }

  @include mq($from: l) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}

.question__inputs {
  display: flex;
  flex-direction: column;

  @include mq($from: l) {
    margin-left: auto;
  }
}

.question__stars {
  margin-bottom: 2rem;
  display: flex;
  z-index: 1;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  &:hover,
  &.has-value {
    .question__star {
      color: $c-main;
    }
  }

  .question__star {
    transition: color 150ms $easing;
    color: $c-pampas;

    ::v-deep {
      .star__label {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    &:hover,
    &:focus,
    &.checked {
      ~ .question__star {
        color: $c-pampas;
      }
    }
  }

  &.has-value:hover {
    .question__star {
      color: $c-main;

      &:hover,
      &:focus {
        ~ .question__star {
          color: $c-pampas;
        }
      }
    }
  }
}

.question__na {
  display: flex;

  @include mq($from: l) {
    justify-content: flex-end;
  }
}

.question__na__input {
  &:checked + .question__na__label {
    .question__na__svg__bullet {
      fill: $c-main;
      opacity: 1;
    }
  }
}

.question__na__label {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  line-height: 2;
  align-items: center;
  user-select: none;
}

.question__na__svg {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
}

.question__na__svg__stroke {
  opacity: 0.2;
  stroke: $c-black;
}

.question__na__svg__bullet {
  opacity: 0.1;
  fill: $c-black;
  transition: all 150ms $easing;
}
</style>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import SatisfactionQuestionStar from '@/components/SatisfactionQuestionStar.vue'
import { useGetters } from '@u3u/vue-hooks'

const stringify = value =>
  typeof value === 'string' ? value : JSON.stringify(value)

export default defineComponent({
  components: { SatisfactionQuestionStar },
  props: {
    headline: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    nullable: {
      required: false,
      default: false,
      type: Boolean,
    },
    id: {
      required: true,
    },
    value: {
      required: false,
    },
  },
  setup(props, ctx) {
    const { content } = useGetters(['content'])
    const hrRef = ref()
    const checked = ref(null)
    const activeTooltip = ref(null)
    const na = ref(false)

    const onInput = value => {
      if (value !== 0) {
        na.value = false
      }
      checked.value = value
      ctx.emit('input', props.id, stringify(value))
    }

    const onNAInput = e => {
      onInput(e.target.checked ? 0 : null)
    }

    return {
      onInput,
      checked,
      hrRef,
      content,
      activeTooltip,
      onNAInput,
      na,
    }
  },
})
</script>
